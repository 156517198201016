<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud :ref="'productsCrud'" crudName="Produtos" newText="Novo Produto" :table="table" :columns="columns"
          :options="options" :endPoint="endPoint" :enableAdd="true" :enableEdit="true" :enableDelete="true"
          :enableView="true" :enableReset="false" :extra="extra" :dataForm="dataForm" @printTag="printTag">
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "productsTable",
      showMessageImport: false,
      productsCrud: {},
      pages: [],
      url: "",
      columns: ["id", "key", "code", "description", "type", "dimension", 'company_id', 'stock', "actions"],
      tableData: ["id", "key", "code", "description", "type", "dimension", 'company_id', 'stock'],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["id", "code", "description", "type", "dimension", 'company_id'],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          key: "Chave",
          code: "Código",
          description: "Descrição",
          type: "Tipo",
          dimension: "Dimensão",
          company_id: "Empresa",
          stock: "Estoque",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {},
        templates: {
          company_id: function (h, row, index) {
            return row.company
              ? row.company.fantasy_name
              : '';
          },
          stock: function (h, row, index) {
            return (
              <h5 class="text-center">
                <span class={row.stock_status}>
                  {row.stock}
                </span>
              </h5>
            );
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ['company'];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "btn btn-crud info",
          title: "Aprovar",
          name: "printTag",
          icon: "fas fa-barcode",
        },
      ],
      endPoint: "products",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },

    query: function (query) {
      let columns = {
        id: 'id',
        key: 'key',
        code: 'code',
        description: 'description',
        type: 'type',
        dimension: 'dimension',
        company_id: 'company[fantasy_name]',
        actions: 'actions',
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
    printTag(row) {
      console.log(row);
      alert('imprimindo etiqueta');
    },
  },
};
</script>

<style scoped></style>
import Crud from '../views/Stock/Crud'
import Entity from '../views/Stock/Entity'

let routes = {
    path: '/stock',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/stock',
            name: 'stock',
            component: Crud,
            meta: {
                pageTitle: 'Movimentar Estoque',
                access_nivel: ['1', '2'],
                breadcrumb: [
                    {
                        text: 'Estoque',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/stock/input',
            name: 'StockInput',
            component: Entity,
            meta: {
                pageTitle: 'Entrada de Estoque',
                access_nivel: ['1', '2'],
                breadcrumb: [
                    {
                        text: 'Entrada de Estoque',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/stock/output',
            name: 'StockOutput',
            component: Entity,
            meta: {
                pageTitle: 'Saída de Estoque',
                access_nivel: ['1', '2'],
                breadcrumb: [
                    {
                        text: 'Saída de Estoque',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
